import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ErrorsList from './ErrorsList'
import { isLoggedIn } from '../services/auth'

const CreateAccountForm = () => {
  if (isLoggedIn()) navigate('/account/manage')

  const [stage, setStage] = useState(0)
  const [errors, setErrors] = useState([])

  return (
    <div className="register-form-container">
      <div className="register-form">
        <div className="title">Create Account</div>
        <Formik
          initialValues={{
            username: '',
            password: '',
            authCode: '',
          }}
          validate={values => {
            const errors = {}
            if (stage === 0 && !values.username) {
              errors.username = 'Required'
            } else if (
              stage === 0 &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
            ) {
              errors.username = 'Invalid email address'
            }
            if (stage === 0 && !values.password) {
              errors.password = 'Required'
            }
            if (stage === 1 && !values.authCode) {
              errors.authCode = 'Required'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (stage === 0) {
              try {
                await Auth.signUp(values.username, values.password)
                setErrors([])
                setStage(1)
              } catch (err) {
                setErrors([err.message])
              }
            } else {
              try {
                await Auth.confirmSignUp(values.username, values.authCode)
                setErrors([])
                navigate('/account/manage')
              } catch (err) {
                setErrors([err.message])
              }
            }
          }}
        >
          {({ isSubmitting }) => {
            if (stage === 0) {
              return (
                <Form>
                  <label htmlFor="username">
                    Email
                    <Field type="email" name="username" />
                    <ErrorMessage name="username" component="div" />
                  </label>
                  <label htmlFor="password">
                    Password
                    <Field type="password" name="password" />
                    <ErrorMessage name="password" component="div" />
                  </label>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </Form>
              )
            } else {
              return (
                <Form>
                  <label htmlFor="authCode">
                    Authorization Code
                    <Field type="text" name="authCode" />
                    <ErrorMessage name="authCode" component="div" />
                  </label>
                  <button type="submit" disabled={isSubmitting}>
                    Create Account
                  </button>
                </Form>
              )
            }
          }}
        </Formik>
        <ErrorsList errors={errors} />
      </div>
    </div>
  )
}

export default CreateAccountForm
