import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import CreateAccountForm from '../components/CreateAccountForm'
import SEO from '../components/SEO'

const Register = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Create Account"
        description="Create Account description goes here"
      />
      <Container>
        <PageTitle>Create Account</PageTitle>
        <CreateAccountForm />
      </Container>
    </Layout>
  )
}

export default Register
